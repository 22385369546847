@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

/* purgecss start ignore */
html,
body {
  /* font-family: 'Lato'; */
  background-color: #fefefe;
  color: "#333333";
}

@layer components {
  .btn-primary {
    @apply bg-primary-500 text-white disabled:opacity-60;
  }
  .btn-secondary {
    @apply border border-primary-500 text-primary-500 disabled:opacity-60;
  }
  .dropdown-item {
    @apply w-fit px-2 flex items-center text-text-black gap-4 hover:text-primary-500;
  }
  .dropdown-item-alt {
    @apply px-2 flex items-center text-text-black gap-4 w-[500px];
  }
  .dropdown-header {
    @apply flex items-center text-text-black text-xl font-semibold space-x-3;
  }
  .inputs-container {
    @apply flex flex-col md:flex-row gap-x-8;
  }
}

/* custom spinner */

.spinner {
  animation: spin 1s ease infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* remove input border, no se quitaba con tw */
input:hover,
input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

/* carousel */
.carousel-img {
  transition: 1s;
}

.carousel-present {
  left: 0;
  opacity: 1;
  visibility: visible;
  position: absolute;
}

.carousel-next {
  position: absolute;
  left: 0vw;

  opacity: 0;
  visibility: hidden;
  animation: fadeIn 5s;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background-color: #dedede;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #097dc1;
  border-radius: 14px;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #5145cd;
  position: fixed;
  z-index: 1060;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #5145cd, 0 0 5px #5145cd;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #5145cd;
  border-left-color: #5145cd;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* purgecss end ignore */
/*Chrome*/
input[type="range"] {
  height: 8px;
  overflow: hidden;
  appearance: none;
  background-color: #eaeaea;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 8px;
  appearance: none;
  border-radius: 100%;
  background-color: #eaeaea;
}

input[type="range"]::-webkit-slider-thumb {
  width: 8px;
  border-radius: 100%;
  appearance: none;
  height: 8px;
  cursor: pointer;
  background: #097dc1;
}

/* Style the thumb when active (clicked) */
input[type="range"]::-webkit-slider-thumb:active {
  background: #0077aa;
}

/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: #097dc1;
}

input[type="range"]::-moz-range-track {
  background-color: #eaeaea;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #097dc1;
}

input[type="range"]::-ms-fill-upper {
  background-color: #eaeaea;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
